import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel, Modal } from 'react-bootstrap';
import Images from "../images";


function Detailmodal(props) {
    console.log("propsprops",props)

    const [detmodal, setDetmodal] = useState(true)
    const [data , setdata] = useState(null)
    useEffect(() => {
        setdata(props.data)
        }, [props.data]);

        const dateFormater =(createdAt)=>{
            const date = new Date(createdAt);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = date.toLocaleDateString('en-US', options);
console.log(formattedDate);  
return formattedDate

        }
    return (

        <>
            <div className="">
                <Modal
                    className="detmodal"
                    // onHide={props.onDismiss}
                    show={detmodal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className="pb-4 px-3 px-sm-5">
                        <h3 className="text-white fw-600">{data?.heading}</h3>
                        <div className="path_cutz text-center mt-4">
                            <img src={data?.image} className="img-fluid detimg"/>
                        </div>
                        <p className="text-white mt-4">
{/* {data?.description}  */}
<div dangerouslySetInnerHTML={{ __html: data?.description }}></div>

                        </p>
                            <div className="d-flex align-items-center gap-1 justify-content-end">
                            <p className="mb-0 text-white">
                                {/* John Doe  */}
                            
                            <span className="ms-1">{dateFormater(data?.createdAt)}</span></p>                         
                            </div>
                    </Modal.Body>
                </Modal>


            </div>
        </>
    )
}

export default Detailmodal;
