import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from "./Landing";

function App() {
  return (
    <div className="App">
           <BrowserRouter>
           <Routes>
             <Route path="/" element={<Landing />} />
           </Routes> 
           </BrowserRouter>
    </div>
  );
}

export default App;
