import React from 'react';
import Images from "./images";

const LeftArrow = ({ onClick }) => (
  <div className='arrow_left' onClick={onClick} style={{ cursor: 'pointer', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
    <img src={Images.rightarrowimg} alt="Left Arrow" />
  </div>
);

const RightArrow = ({ onClick }) => (
  <div className='arrow_right' onClick={onClick} style={{ cursor: 'pointer', position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
    <img src={Images.leftarrowimg} alt="Right Arrow" />
  </div>
);

export { LeftArrow, RightArrow };
