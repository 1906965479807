import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel, Modal } from 'react-bootstrap';
import Images from "../images";
import config from "../config"
import ReactPlayer from "react-player";

function Newssmodal(props) {

    const [newmodal, setNewmodal] = useState(true);

    const [detmodal, setDetmodal] = useState(true)
    const [data, setdata] = useState(null)
    useEffect(() => {
        console.log('dosagduyghxhx')
        setdata(props.data)
    }, [props.data]);
    const dateFormater = (createdAt) => {
        const date = new Date(createdAt);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        console.log(formattedDate);
        return formattedDate

    }
    return (

        <>
            <div className="">
                <Modal
                    className="detmodal"
                    // onHide={props.onDismiss}
                    show={newmodal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className="pb-4 px-3 px-sm-4 px-md-5">

                        <h3 className="text-white fw-600">{data?.heading}</h3>

                        {data?.video ?
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="500px"
                                url={`${config.CDN}${data?.video}`}
                                controls={true}
                            /> :
                            <div className="path_cutz text-center mt-4">
                                <img src={data?.image} className="img-fluid detimg newslatest_img" />
                            </div>
                        }
                        <p className="text-white mt-4">
                            <div  dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                        </p>

                        <div className="d-flex align-items-center gap-1 justify-content-end">
                            <p className="mb-0 text-white">
                                {/* John Doe  */}
                                <span className="ms-1">{dateFormater(data?.createdAt)}</span></p>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
        </>
    )
}

export default Newssmodal;
